import { Injectable } from "@angular/core";

// Global properties
Injectable()
export class Globals {

    // Application Generic
    public static ApplicationName = 'SMP';

    // Points API Call
    public static GetPointsBalance = 'api/Points/GetSWPointsBalance';
    public static GetAdrData = 'api/Points/GetAdrData';
    public static GetTransactionHistory = 'api/Points/GetSWPointsDetails';
    public static GetAwardPointsDetails = 'api/Points/GetAwardPointsDetails';
    public static GetRedeemPointsDetails = 'api/Points/GetOrderPointsDetails';


    // ADR API Call
    public static GetAdrUrl = 'api/adrintegration/GetPinsRedemptionURL';

    // Teen Consent API Call
    public static GetTeenConsentInfo = 'api/TeenConsent/GetTeenConsentInfo';
    public static PostTeenConsentInfo = 'api/TeenConsent/PostTeenConsentInfo';

    // SMTP Email API Call
    public static SendEmail = 'api/Email/SOWContactUsNotification';

    // Login API Call
    public static GetAuthenticateUserDetails ="api/Login/GetAuthenticateUserDetails";    
    public static GetOffPanelDetails ="api/Login/AuthenticateNeoCode";
    public static AuditDelatilsLogs="api/status/ContinueVactions";
    public static CheckLinkExpiry="api/Login/CheckLinkExpiry";
    public static ResetPasswordInformation="api/Login/ResetPasswordInformation";
    public static AuthenticateGRecaptcha = 'api/Login/AuthenticateGRecaptcha';
    public static UpdateLogoutAuditlog = "api/Login/UpdateLogoutAuditlog";
    
    //Member Profile
    public static GetMemberAddressDeatails="api/Profile/GetMemberAddressDetails";
    public static GetMemberProfileInfo="api/Profile/GetMemberInfo";
    public static UpdateProfileEmail="api/Profile/UpdateProfileEmail";
    public static UpdateProfileName="api/Profile/UpdateProfileName";
    public static UpdateProfilePassword = "api/Profile/UpdateProfilePassword";
    public static GetOtherHouseholdMembers = "api/Profile/GetOtherHouseholdMembers";
    public static UpdatePersonalPhoneInfo = "api/Profile/UpdatePersonalPhoneInfo";
    public static UpdateHouseholdPhoneInfo = "api/Profile/UpdateHouseholdPhoneInfo";
    public static UpdatePersonalCommunicationPref = "api/Profile/UpdatePersonalCommunicationPref";
    public static GetAddressStateList = "api/Profile/GetAddressStateList";
    public static UpdateMemberAddress = "api/Profile/UpdateMemberAddress";
    public static readonly IsValidMeterActivityPresent = "api/Screenwise/IsValidMeterActivityPresent"
    public static GetResourcesByPanelId = "api/Localization/GetResourcesByPanelId";
    public static MyPrivacyData="api/PanelPolicy/GetPanelPolicyAudit";
    public static PostpolicyData="api/PanelPolicy/AddPolicyAudit";
    public static UpdateHouseholdCommunicationPref = "api/Profile/UpdateHouseholdCommunicationPref";
    public static readonly GetCaptchaValue = "api/Localization/GetCaptchaValue";

     /** Custom Message API Calls **/
     public static GetCustomMessage = 'api/CustomMessage/GetMemberInfo';
     public static UpdateReadNotificationFlag='api/CustomMessage/UpdateNotificationReadFlag'

     // Google Integration API Call
     public static GetEmailVerificationLink = 'api/SWEmailVerification/GetEmailVerificationLink';
    public static readonly WelcomeHomeBookmark = '/home/welcome';
    public static readonly AccountHomeBookmark = '/home/account';
}

export enum ContactOption {
    Email = 0,
    Phone = 1
}

export enum ConsentOption {
    NotSelected = 0,
    Accept = 1,
    Decline = 2
}


export enum TransactionType {
    Reward = 0,
    Redemption = 1,
    UnderSettlement = -1
}
