import { Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized, NavigationStart } from '@angular/router';
import {map,filter, pairwise} from 'rxjs/operators';
import { ResourcesModel } from './model/ResourcesModel';
import { LocalizationService } from 'src/app/services/localization.service';
import { AppGlobals } from './model/app.global';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { UserIdleService } from 'angular-user-idle';
import { SignInService } from './services/signin.service';
import * as CryptoJS from 'crypto-js';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent  implements OnInit{
  
  localModel: ResourcesModel[] =  [];
  httpOptions: { headers: any; };
  localizationModel: ResourcesModel[];
  envName:string;
  previousUrl: any;
  domainURLs : string[];
  encryptInfo:any;
  isUserSignIn: boolean;

  constructor(private titleService: Title,private router:Router,private activatedRoute:ActivatedRoute,
    private localizationService : LocalizationService,private appGlobal: AppGlobals,
    private spinnerSrv: NgxSpinnerService,private usrIdle:UserIdleService,private signinService:SignInService,
    private elementRef: ElementRef) {
  }

  ngOnInit(): void {   
    this.envName = environment.envName;
    this.elementRef.nativeElement.removeAttribute("ng-version");
    this.spinnerSrv.show();
    this.isUserSignIn = this.signinService.isSignIn();

    this.domainRedirection();  
    this.usrIdle.stopTimer();
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart)
    )   
    .subscribe((event: NavigationStart) => {
      this.isUserSignIn = this.signinService.isSignIn();

      if (this.isUserSignIn)
      {
        if(event.url === '/home/welcome'  && (!localStorage.getItem('reset-password')))
        {
         this.router.navigate(['dashboard'], { fragment: 'screenwise-meter' });
        }      
        else if (event.url === '/home/account' && (!localStorage.getItem('reset-password')))
        {
           this.router.navigate(['myprofile'], { fragment: 'google-screenwise-meter-account' });
        } 
      } 
   });  
   
      if(this.signinService.gettoken()){
        this.usrIdle.setConfigValues({idle:environment.SessionTimeout,timeout:1,ping:0}) 
        this.usrIdle.startWatching();       
      }
        

    /** Subscribe the Idle Time Completed Event (This is required to start for the timeout process.) */
    /** This is also useful as a grace period for user's action */
    this.usrIdle.onTimerStart().subscribe(count => console.log(count));
  
      /** Event to Handle timeout activity. */
      this.usrIdle.onTimeout().subscribe(() => {      
          this.signinService.logout();
        
      });

    this.router
      .events.pipe(
        filter((event) : event is NavigationEnd => event instanceof NavigationEnd),
        map(event => {
          gtag('event', 'page_view', { page_path: event.urlAfterRedirects})
        })
      ).subscribe(() => {
       
        window.scrollTo(0, 0)
        this.signinService.profiletogleChanged.next();
      });

      this.router.events.subscribe((data) => {
        if (data instanceof RoutesRecognized) {           
        //var name = data.state.root.firstChild.data.title;
       // this.titleService.setTitle(name);
      // this.localizationService.titleChanged.next();
        }
      });
      this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
          pairwise()
      ).subscribe((e: any) => {
        this.previousUrl= e[0].urlAfterRedirects;
        sessionStorage.setItem('priviousroute',this.previousUrl) // previous url
  });
        
    this.fetchLocalizationResources();
    this.getCaptchaValue();
  }

  domainRedirection(){
    //following code will handle multiple domain redirection
    this.domainURLs = environment.DomainURL.split(',');
    var currentDomain = window.location.origin.replace(/^https?:\/\//, '').trim().toLowerCase();
    
    var page = window.location.pathname.split("//").toString().toLowerCase();
    for(var ii=0;ii<this.domainURLs.length;ii++){
      //domain matches to config domain then redirect it appropriate domain/page
      if(this.domainURLs[ii].trim().toLowerCase() ===  currentDomain){
          // window.location.href = AppConfig.configurations.appSettings.RedirectDomain+page;
          window.location.href = environment.RedirectDomain+page;
          return;
      }
    }
  }

  fetchLocalizationResources() {
    if(this.localModel == null || this.localModel.length == 0){
    this.localizationService.getResouces().subscribe(res => {
      //console.log(res[0].attributes);
       localStorage.setItem("english",res[0].attributes);
       localStorage.setItem("spanish",res[1].attributes);
       this.localModel = JSON.parse(res[0].attributes); 
      this.localizationService.setLocalization(res);
        /** spinner ends after 5 seconds */
        
      //console.log(this.localModel);
      setTimeout(() => {
        this.spinnerSrv.hide();  
      }, 1000);
      
    });
  }
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });

  }
  getCaptchaValue() {
  
    this.localizationService.getCaptchaKey().subscribe(res=> {
      this.encryptInfo=CryptoJS.AES.encrypt(JSON.stringify(res) ,'').toString();    
       localStorage.setItem("SiteValue",this.encryptInfo);     
       setTimeout(() => {
        this.spinnerSrv.hide();  
      }, 1000);    
    });
  }

}


