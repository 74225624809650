import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html'
})
export class SignoutComponent implements OnInit {

  showsignOutHeader:boolean = true;
  constructor(private router:Router,private localization:LocalizationService) { }

  ngOnInit(): void {  
  }

  onClose(){
   // this.showsignOutHeader = false;
   this.localization.removeLocalStorageVariable('currentuser');
   this.localization.removeLocalStorageVariable('restrict');
   this.localization.removeLocalStorageVariable('showviewhelpcenter');
    sessionStorage.clear();
    this.localization.setIsSignOutVariable("true");
    this.localization.changeLocalization("english")
    this.router.navigate(['']);
  }
}
