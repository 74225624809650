import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../model/apiResponse.model';
import { Address, AddressDetails, AddressInfo, UpdateAddress } from '../model/memberAddressInfo.model';
import { Globals } from '../shared/shared.global';


@Injectable({ providedIn: 'root' })
export class MemberAddressService {
  httpOptions: { headers: HttpHeaders; };
  addressInfo = new AddressInfo();
  addressChanged = new Subject<AddressDetails>();
  addressDetails = new AddressDetails();

  apiBasePath: string;
  apiKey: string;


  constructor(private httpClient: HttpClient) {
    this.apiBasePath = environment.Member_Profile_API_Base_Path;
    // this.apiKey = environment.Member_Profile_API_Key;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })

    };
  }

  getMemberAddress(MNO: number) {

    this.addressInfo.MNO = MNO;
    //pipe operator take will only take first subscription and automatically unsubcribe 
    return this.httpClient.post<APIResponse>(
      this.apiBasePath + Globals.GetMemberAddressDeatails,
      this.addressInfo, this.httpOptions
    ).subscribe(res => {
      this.addressDetails = res.Data.AddressDetails;

      if (this.addressDetails.Residential)
        this.addressDetails.Residential.CompleteAddress = this.generateCompleteAddress(this.addressDetails.Residential);
      else
        this.addressDetails.Residential = new Address();

      if (this.addressDetails.Mailing)
        this.addressDetails.Mailing.CompleteAddress = this.generateCompleteAddress(this.addressDetails.Mailing);
      else
        this.addressDetails.Mailing = new Address();
      
      if (this.addressDetails.Shipping)
        this.addressDetails.Shipping.CompleteAddress = this.generateCompleteAddress(this.addressDetails.Shipping);
      else
        this.addressDetails.Shipping = new Address();

      if (this.addressDetails.Mailing.SameAsResidenceForMailing == null) {
        this.addressDetails.Mailing.SameAsResidenceForMailing = false;
      }


      if (this.addressDetails.Shipping?.SameAsMailingForShipping == null) {
        this.addressDetails.Shipping.SameAsMailingForShipping = false;
      }

      if (this.addressDetails.Shipping?.SameAsResidenceForShipping == null) {
        this.addressDetails.Shipping.SameAsResidenceForShipping = false;
      }

      if (this.addressDetails.Shipping.SameAsResidenceForShipping === true ||
        this.addressDetails.Shipping.SameAsMailingForShipping === true) {
        this.addressDetails.Shipping.IsShippingAddressChecked = true;
      }


      this.setAddress(this.addressDetails);

    });
  }

  // function to generate a complete address
  generateCompleteAddress(address: any): string {
    return (
      (address.AddressLine1 ? address.AddressLine1 + '<br/> ' : '') +
      (address.AddressLine2 ? address.AddressLine2 + '<br/>' : '') +
      (address.City ? address.City + ', ' : '') +
      (address.StateInfo.StateCode ? address.StateInfo.StateCode + ', ' : '') +
      (address.ZipCode ? address.ZipCode : '')
    ).trim();
  }

  setAddress(res: AddressDetails) {
    this.addressChanged.next(this.addressDetails);
  }

  getAddress() {
    return this.addressDetails;
  }

  getStates() {
    //pipe operator take will only take first subscription and automatically unsubcribe 
    return this.httpClient.get<APIResponse>(
      this.apiBasePath + Globals.GetAddressStateList,
      this.httpOptions
    );
  }


  saveAddress(address: UpdateAddress) {
    return this.httpClient.post(
      this.apiBasePath + Globals.UpdateMemberAddress,
      address, this.httpOptions);
  }

}
