<div class="rewards-redeem">

    <h2>{{ localModel.lbl_MyReward_RedeemRewards }}</h2>

    <ul class="reward-examples">
        <li class="reward-example">
            <img src="assets/images/reward_prepaid_cards.png" alt="" />
            <div class="small text-muted">{{ localModel.lbl_MyReward_PrepaidCards }}</div> 
        </li>
        <li class="reward-example">
            <img src="assets/images/reward_gift_choices.png" alt="" />
            <div class="small text-muted">{{ localModel.lbl_MyReward_GiftChoices }}</div>
            	
        </li>
        <li class="reward-example">
            <img src="assets/images/reward_gift_cards.png" alt="" />
            <div class="small text-muted">{{ localModel.lbl_MyReward_GiftCards }}</div>
            	
        </li>
    </ul>

    <p [title]="disableCardButton? cardButtonTitle:''">
        <button class="btn btn__primary btn--full-width" [disabled]="disableCardButton" (click)="onButtonClick()" style="line-height: inherit;">
            {{ localModel.lbl_MyReward_GoToRewardCenter }}
            <img src="assets/images/icons/icon_link_external.svg" class="img--external" alt="" />
        </button>       
    </p>
    
</div>