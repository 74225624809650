import { Component, OnInit } from '@angular/core';
import {  NgForm } from '@angular/forms';
import { APIResponse } from 'src/app/model/apiResponse.model';
import { MyProfileModel, UpdateProfile } from 'src/app/model/myProfile.model';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { UserLogInfo } from 'src/app/model/UserInfo';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignInService } from 'src/app/services/signin.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  toggleLen :boolean = false;
  toggleUp :boolean = false;
  toggleLow :boolean = false;
  toggleNum :boolean = false;
  toggleSpch :boolean = false;
  value :string;
  type :string;
  toggle : boolean=false;
  show : boolean=false;
  errorMessage : string;
  isSuccess: boolean = false;
  localModel: any;
  myProfile : MyProfileModel;
  userDetails:UserLogInfo;
  passwordPolicy : string;
  isPasswordPolicy:boolean = false;
  beforeLogin:boolean=false;
  isError:boolean=true;
  returnUrl: any;

  withdrawnDays: any;
    constructor(private myProfileService: MyProfileService, private localizationService: LocalizationService, 
      private spinnerSrv: NgxSpinnerService,private route:Router,private signinService:SignInService,
      private dialog:MatDialog,private titleService:Title,private activatedRoute: ActivatedRoute) {
        var userDataFromSession= this.localizationService.getCurrentUserVariable();
        this.userDetails = userDataFromSession;
        this.route.routeReuseStrategy.shouldReuseRoute = () => {
          return false;
        };
    }

  ngOnInit(): void {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';    
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_changepwd_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_changepwd_browsertitle)
      })

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.spinnerSrv.show();
    this.myProfile = this.myProfileService.getProfile();
    
    this.userDetails = this.localizationService.getCurrentUserVariable();
    if(this.myProfile!==null) this.beforeLogin=true;
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinnerSrv.hide();
  }, 500);
  localStorage.setItem('password-not-changed', '1');
  }

  confirmNewPasswordShow:boolean;
  newPasswordShow:boolean;
  oldPasswordShow:boolean;
  onShowHideClick(whichToShow:number){
    switch(whichToShow){
      case 1:this.oldPasswordShow = !this.oldPasswordShow;
        break;
      case 2:this.newPasswordShow = !this.newPasswordShow;
        break;
      case 3:this.confirmNewPasswordShow = !this.confirmNewPasswordShow;
        break;
    }
  }

  onSubmit(form:NgForm){
    var updateProfile = new UpdateProfile();
    updateProfile.PanelMemberId = this.userDetails.PanelMemberId;
    updateProfile.MNO = this.userDetails.MNo;
    updateProfile.NewPassword = form.value.newPassword;
    updateProfile.OldPassword = form.value.oldPassword;
    updateProfile.ExistingPasswordKey = this.myProfile!==null?'':this.userDetails.UserPassKey;
    this.spinnerSrv.show();
    this.myProfileService.updatePassword(updateProfile).subscribe((res:APIResponse) =>{     
      if(res!==null && res.Code=='105'){
        this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);            
      }
      switch(res.Data.UpdateStatus.Status){
        case 0:this.errorMessage = "";
        this.isSuccess = true;
          break;
        case 1: this.errorMessage = this.localModel.lbl_please_enter_valid_old_password;    
        this.isError=false;
          break;
        case 2:this.errorMessage = this.localModel.lbl_newpassword_oldpassword_must;          
        this.isError=false;
        break;
        case 3:this.errorMessage = this.localModel.lbl_profile_userpassCombExists;          
        this.isError=false;
        break;
      }

        this.spinnerSrv.hide();
        
        if(this.isSuccess){
          this.isError=false;
          if(this.userDetails.UserLoginResult===-3 && this.myProfile===null){
            this.withdrawnDays=environment.counter;
            if(this.userDetails.days>this.withdrawnDays){
              this.localizationService.setWithDrawanPopUpVariable();
              this.showPopup(this.localModel.lbl_changepassword_withdrawnlbl,this.localModel.lbl_signin_valid_2)            
              this.route.navigate(['/'])                       
              return;
            }

            this.localizationService.removesessionStorageVariable('loginresult');
            this.signinService.userLoginChanged.next();    
            if (sessionStorage.getItem('redirectUrl')?.toLowerCase().includes('/myprofile')) {
              this.route.navigate(['myprofile']);            
            }
          
            else {
              this.route.navigate(['dashboard']);             
            }

          }
          else{
            this.route.navigate(['myprofile'])              
          }
         this.showPopup(this.localModel.lbl_ChangePass_Title,this.localModel.lbl_PasswordChanged);

        }else{
          this.showPopup(this.localModel.lbl_ChangePass_Title,this.errorMessage);
        }    
       });
   
    if(this.isError){
      this.route.navigated = false;
      this.route.navigate([this.route.url])
    }
    localStorage.removeItem('reset-password');
  }

  private showPopup(heading: string, message: string) {

    const dialogRef=this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result){
        this.localizationService.changeLocalization("english");
        this.localizationService.removeLocalStorageVariable("withdrawnpopup");       
      }   

      if (sessionStorage.getItem('welcome') == '1') {
        this.route.navigate(['dashboard'],{ fragment: 'screenwise-meter' })
        sessionStorage.removeItem('welcome');
      }
      if (sessionStorage.getItem('WelcomeAccount') === '1') {
        this.route.navigate(['myprofile'],{ fragment: 'google-screenwise-meter-account' })
        sessionStorage.removeItem('WelcomeAccount');
         }

     
    });
  }


  onClickRedirect(){

    if(this.myProfile==null){
      this.localizationService.changeLocalization("english")
      this.route.navigate(['/'])   
    }else{
      this.route.navigate(['myprofile'])   
    }
  }
  
  onkeyUp(event){
    this.value = event.target.value;
    var array : string[]=[];
    document.querySelector("[data-check=length]").className = this.value.length >= 7 ? "met" : "not-met";
    array.push(document.querySelector("[data-check=length]").className);

    this.passwordPolicy =  document.querySelector("[data-check=uppercase]").className = this.value.match(/[A-Z]/) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=uppercase]").className);

    this.passwordPolicy =  document.querySelector("[data-check=lowercase]").className = this.value.match(/[a-z]/) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=lowercase]").className);
    this.passwordPolicy =  document.querySelector("[data-check=number]").className = this.value.match(/\d/) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=number]").className);
    this.passwordPolicy =  document.querySelector("[data-check=specialcharacter]").className = this.value.match(
        /[!@#$%^&*~`()_+\-=\[\]{};':"\\|,.<>\/?]+/
      )
        ? "met"
        : "not-met";     

        array.push(document.querySelector("[data-check=specialcharacter]").className);   
     if(array.filter(policy => policy == "not-met").length > 0){
       this.isPasswordPolicy = false;
     }   else{
       this.isPasswordPolicy = true;
     }
      
    // if(this.value.length >= 7)
    // {
    //  this.toggleLen = true;   
    // }
    // else{this.toggleLen = false;}
 
    // if(this.value.match(/[A-Z]/))
    // {
    //  this.toggleUp = true;    
    // }
    // else{ this.toggleUp = false;}
 
    // if(this.value.match(/[a-z]/))
    // {
    //  this.toggleLow = true;
    // }
    // else{this.toggleLow = false;}
 
    // if(this.value.match(/\d/))
    // {
    //  this.toggleNum = true;
    // }
    // else{this.toggleNum = false;}
    // if(this.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/))
    // {
    //  this.toggleSpch = true;
    // }
    // else{this.toggleSpch = false;}
    
   }
 

}

