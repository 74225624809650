<div class="my-address-phone">
    <!-- Addresses -->
    <div class="my-profile__address" *ngIf="memberAddress">
        <h2 [innerHtml]="localModel.lbl_MyProfile_Address"></h2>
        <p *ngIf="hideInfo === true else hideAddresses">
            {{localModel.lbl_ProfileInfoWillBeAvailable}}
        </p>

        <ng-template #hideAddresses>
            <ul>
                <li>
                    <h3 [innerHtml]="localModel.lbl_MyProfile_ResidenceAddress"></h3>
                    <p [innerHtml]="memberAddress.Residential.CompleteAddress">
                    </p>

                </li>
                <li>
                    <h3 [innerHtml]="localModel.lbl_MyProfile_MailingAddress"></h3>
                    <p *ngIf="memberAddress.Mailing.SameAsResidenceForMailing">
                        {{localModel.lbl_dashboard_Same_Resd}}
                    </p>
                    <p *ngIf="!memberAddress.Mailing.SameAsResidenceForMailing"
                        [innerHtml]="memberAddress.Mailing.CompleteAddress">
                    </p>

                </li>
                <li>
                    <h3 [innerHtml]="localModel.lbl_MyProfile_ShippingAddress"></h3>
                    <p *ngIf="memberAddress.Shipping.SameAsResidenceForShipping">
                        {{localModel.lbl_dashboard_Same_Resd}}
                    </p>
                    <p *ngIf="memberAddress.Shipping.SameAsMailingForShipping">
                        {{localModel.lbl_sameasmailing}}
                    </p>
                    <p *ngIf="!memberAddress.Shipping.SameAsResidenceForShipping && !memberAddress.Shipping.SameAsMailingForShipping"
                        [innerHtml]="memberAddress.Shipping.CompleteAddress">
                    </p>
                </li>
            </ul>
            <a (click)="onChangeAddress()" class="btn btn__primary btn--small"
                [innerHtml]="localModel.btn_MyProfile_ChangeAddress">Change Address</a>
        </ng-template>
    </div>

    <!-- Phone Numbers -->
    <div class="my-profile__phone">
        <h2 [innerHtml]="localModel.lbl_MyProfile_Phone"></h2>
        <ul>
            <li>
                <h3 [innerHtml]="localModel.lbl_MyProfile_HouseholdPhone"></h3>
                <p *ngIf="myProfile.HHPhoneNumber;else hhPhoneNum">{{myProfile.HHPhoneNumber}}</p>
                <ng-template #hhPhoneNum>
                    <p>{{localModel.lbl_myprofile_phone_placeholder}}</p>
                </ng-template>
            </li>
            <li>
                <h3 [innerHtml]="localModel.lbl_MyProfile_PersonalPhone"></h3>
                <p *ngIf="myProfile.PPPhoneNumber;else ppPhoneNum">{{myProfile.PPPhoneNumber}}</p>
                <ng-template #ppPhoneNum>
                    <p>{{localModel.lbl_myprofile_phone_placeholder}}</p>
                </ng-template>
            </li>
        </ul>

        <a routerLink="/change-phone-number" class="btn btn__primary btn--small"
            [innerHtml]="localModel.btn_MyProfile_ChangePhone"></a>
    </div>
</div>
