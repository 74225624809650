<div class="panel">
   <div class="panel__header">
      <h2>{{ localModel.lbl_dashboard_rewardHeader }}</h2>
   </div>

   <div class="panel__body rewards">
      
      <div class="rewards__available pad-left">
         <div class="rewards__available-points content-reward">
            {{ balance | number }}
         </div>
         <p class="content-reward">{{ localModel.lbl_dash_reward_redeem }}</p>
      </div>

      <div class="panel__cta pad-right">
         <ul>
            <li class="btn-list">
               <a routerLink="/myrewards" class="btn btn__primary btn--small btn-full-width" style="line-height: inherit;">{{ localModel.lbl_dashboard_rewardTitle }}</a>
            </li>
            <li class="btn-list" [title]="disableCardButton? cardButtonTitle:''"> 
                  <button class="btn btn__primary btn--small btn-full-width" [disabled]="disableCardButton" (click)="onButtonClick()" style="line-height: inherit;">
                     {{ localModel.lbl_dashboard_rewardLink }}
                     <img src="assets/images/icons/icon_link_external.svg" class="btn--img" alt="" />
                  </button>       
            </li>
         </ul>        
      </div>

      <!-- Phase 2 -->
   </div>
</div>
    