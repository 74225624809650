import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../model/apiResponse.model';
import { CommunicationPrefRequest } from '../model/CommunicationPrefRequest';
import { UpdateProfile,MyProfileModel, GetOtherHHModel } from '../model/myProfile.model';
import { UpdatePhoneInfo } from '../model/phone-info.model';
import { UserLogInfo } from '../model/UserInfo';
import { Globals } from '../shared/shared.global';
import { LocalizationService } from './localization.service';
import { SetPassword } from '../model/Request/SetPassword';

@Injectable({providedIn:'root'})
export class MyProfileService {
    httpOptions: { headers: HttpHeaders; };
    
    apiBasePath: string;
    apiKey: string;

    myProfile:MyProfileModel = null;
    myProfileChanged= new Subject<MyProfileModel>();
    isPartiallyCreatedProfileSub = new BehaviorSubject<boolean>(null);
    localModel : any;
    constructor(private httpClient:HttpClient,private localizationService:LocalizationService){
      this.apiBasePath = environment.Member_Profile_API_Base_Path;
        //this.apiKey = environment.Member_Profile_API_Key;
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };
          this.localModel = this.localizationService.getLocalization();
          this.localizationService.localizationChanged.subscribe(res => {
            this.localModel = res;
            });
            
        }

    
    
    getMemberProfile(){
      var userData = new UserLogInfo();
      userData = this.localizationService.getCurrentUserVariable();
        //pipe operator take will only take first subscription and automatically unsubcribe 
         return this.httpClient.post<APIResponse>(
          this.apiBasePath + Globals.GetMemberProfileInfo , userData.MNo, //1015013
           this.httpOptions
         )
         .pipe(
           tap(res =>{
             if(res.Data.MemberInfo.IsPrimary == true){
                res.Data.MemberInfo.MemberStatusString = this.localModel.lbl_myprofile_ser_1;
             }
             else{
              res.Data.MemberInfo.MemberStatusString = this.localModel.lbl_myprofile_ser_2;
             }
            
             switch(res.Data.MemberInfo.MemberStatus){
              case 0: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_3;
                  break;
                 
                case 1: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_4;
                  break;
                case 2: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_5;
                    break;
                case 3: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_6;
                  break;
                case 4: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_7;
                  break;
                case 5: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_8;
                  break;
                case 6: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_9;
                  break;
                case 7: res.Data.MemberInfo.MembershipString = this.localModel.lbl_myprofile_ser_10;
                  break;
             }
             
             if (res.Data.MemberInfo.HouseholdPhoneNumber) {
              res.Data.MemberInfo.HHPhoneNumber = "("+ res.Data.MemberInfo.HouseholdPhoneNumber.Section1 +")"
              +" "+ res.Data.MemberInfo.HouseholdPhoneNumber.Section2 +"-"+ res.Data.MemberInfo.HouseholdPhoneNumber.Section3;
            }

            if (res.Data.MemberInfo.PersonalPhoneNumber) {
              res.Data.MemberInfo.PPPhoneNumber = "("+ res.Data.MemberInfo.PersonalPhoneNumber.Section1 +")"
              +" "+ res.Data.MemberInfo.PersonalPhoneNumber.Section2 +"-"+ res.Data.MemberInfo.PersonalPhoneNumber.Section3;
            }
             
            if(res.Data.MemberInfo.PersonalConsentIVR == null || res.Data.MemberInfo.PersonalConsentIVR == 0){
              res.Data.MemberInfo.PersonalConsentIVR = 2;
            }
            
            if(res.Data.MemberInfo.PersonalConsentSMS == null || res.Data.MemberInfo.PersonalConsentSMS == 0){
              res.Data.MemberInfo.PersonalConsentSMS = 2;
            }

            if(res.Data.MemberInfo.PersonalIsMobile == null || res.Data.MemberInfo.PersonalIsMobile == 0){
              res.Data.MemberInfo.PersonalIsMobile = 2;
            }

            if(res.Data.MemberInfo.HouseholdIsMobile == null || res.Data.MemberInfo.HouseholdIsMobile == 0){
              res.Data.MemberInfo.HouseholdIsMobile = 2;
            }
            

            if(res.Data.MemberInfo.PersonalPhoneSameasHH == null || res.Data.MemberInfo.PersonalPhoneSameasHH == 0){
              res.Data.MemberInfo.PersonalPhoneSameasHH = false;
            }

            

            this.setProfile(res.Data.MemberInfo);  
           })
         );
    }

    setProfile(myProfile:MyProfileModel){
      this.myProfile = myProfile;
      this.myProfileChanged.next(this.myProfile);
      this.localizationService.removesessionStorageVariable('myProfile');         
      sessionStorage.setItem("myProfile",JSON.stringify(myProfile));
    }
    getProfile(){
      this.myProfile = JSON.parse(sessionStorage.getItem("myProfile"));
      return this.myProfile;
    }

    
    updateEmailAddress(updateProfile:UpdateProfile){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdateProfileEmail,
        updateProfile,this.httpOptions
      );     
    }

    updateFirstNLastName(updateProfile:UpdateProfile){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdateProfileName,
        updateProfile,this.httpOptions
      )     
    }

    updatePassword(updateProfile:UpdateProfile){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdateProfilePassword,
        updateProfile,this.httpOptions
      );
    }
    
    setProfilePassword(data: SetPassword): Promise<any> {
      this.httpOptions.headers = this.httpOptions.headers.set('Recaptcha', data.Captcha);
      return this.httpClient.post(this.apiBasePath + Globals.SetProfilePassword, data, this.httpOptions).toPromise();
    }
    
    getOtherHHMembers(getOtherHHModel : GetOtherHHModel){
      
      return this.httpClient.post<APIResponse>(
        this.apiBasePath + Globals.GetOtherHouseholdMembers,
        getOtherHHModel,this.httpOptions
      );     
    }

    updatePersonalPhoneInfo(updatePhoneInfo : UpdatePhoneInfo){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdatePersonalPhoneInfo,
        updatePhoneInfo,this.httpOptions
      );
    }

    updateHoseholdPhoneInfo(updatePhoneInfo : UpdatePhoneInfo){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdateHouseholdPhoneInfo,
        updatePhoneInfo,this.httpOptions
      );
    }

    updateCommunicationPref(communicationPrefRequest:CommunicationPrefRequest){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdatePersonalCommunicationPref,
        communicationPrefRequest,this.httpOptions
      );
    }

    updateHoseholdCommunicationPref(communicationPrefRequest : CommunicationPrefRequest){
      return this.httpClient.post(
        this.apiBasePath + Globals.UpdateHouseholdCommunicationPref,
        communicationPrefRequest,this.httpOptions
      );
    }

    checkValidMeterActivity(mno: number): Observable<any> {
      return this.httpClient.post(this.apiBasePath + Globals.IsValidMeterActivityPresent,
        mno,
        this.httpOptions
      );
    }
    gettokenExpired(){
      // var userData = new UserLogInfo();
      // userData = JSON.parse(localStorage.getItem("currentuser"));
      // return this.httpClient.post(
      //   this.apiBasePath + Globals.UpdateHouseholdCommunicationPref,
      //   userData.token,this.httpOptions
      // );
    }

    public refreshPartiallyCreatedFlag(mno: number) {
      this.checkPartiallyCreatedFlag(mno).subscribe(res => {
        this.isPartiallyCreatedProfileSub.next(res.Data.IsPartiallyCreated);
      });        
    }

    private checkPartiallyCreatedFlag(mno: number): Observable<any> {
      return this.httpClient.get<any>(`${this.apiBasePath}${Globals.IsPartiallyCreatedMember}${mno}`, this.httpOptions);
    }
 
}


