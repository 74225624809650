import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { AdrData } from 'src/app/model/adrData.model';
import { RewardsData } from 'src/app/model/rewardsData.model';
import { UserLogInfo } from 'src/app/model/UserInfo';
import { LocalizationService } from 'src/app/services/localization.service';
import { RewardsInfoService } from 'src/app/services/my-rewards/rewardsInfo.service';
import { environment } from 'src/environments/environment';
import { PointsDataModel } from 'src/app/model/PointsData.model';

@Component({
  selector: 'app-myrewards',
  templateUrl: './myrewards.component.html',
  styleUrls: ['./myrewards.component.css']
})
export class MyrewardsComponent implements OnInit {

  mno: number;
  pointsBalance: number;
  panelName: string;
  notificationHeader: string;
  notificationMsg: string; 
  showNotification: boolean;
  showExtraNotificationBtn: boolean;
  checkForTaxCompliance: boolean;
  adrData: AdrData;  
  loginDetails: UserLogInfo;
  localModel: any;
  rewardsData: any;
  isBlockedForRedemption: boolean;

  constructor(private rewardInfoSrv: RewardsInfoService, private spinnerSrv: NgxSpinnerService, private dialog: MatDialog, 
    private localizationService: LocalizationService,private titleService:Title,public rewardinfoService: RewardsInfoService) { 
    
    // initialize variables
    this.showNotification = false;
    this.panelName = environment.PanelName;
    this.checkForTaxCompliance = environment.CheckForTaxCompliance;

    if (localStorage.currentuser !== null) {
      this.loginDetails = this.localizationService.getCurrentUserVariable();
      this.mno = this.loginDetails.MNo;
    }
  }

  ngOnInit(): void {
    
    this.spinnerSrv.show();

    this.localModel = this.localizationService.getLocalization();

    this.titleService.setTitle(this.localModel.lbl_rewards_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_rewards_browsertitle)
      })
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });

    this.getPointsBalance();
  }

  // get current points balance from service
  private getPointsBalance() {

    this.rewardInfoSrv.getPointsBalance(this.mno).subscribe((responseData: PointsDataModel) => {

      this.pointsBalance = responseData.PointsBalance;
      this.isBlockedForRedemption = responseData.IsBlockedForRedemption;
      if(this.isBlockedForRedemption){
        this.showPopupForPointRestriction(this.localModel.lbl_Popup_Heading_Alert, this.localModel.lbl_Redemption_Temporarily_Blocked);
      }
      this.spinnerSrv.hide();
    },
    errorResponse => {
      this.spinnerSrv.hide();
    });
  }

  // redirect user to ADR site
  redeemRewards() {
    this.spinnerSrv.show();    
    this.rewardsData= new RewardsData();
    this.rewardsData.MNO=this.mno;
    this.rewardsData.PanelName=this.panelName;
    this.rewardsData.CheckForTaxCompliance=this.checkForTaxCompliance;
    this.rewardsData.PanelMemberId=this.loginDetails.panelmemberid;
    this.rewardInfoSrv.getAdrData(this.rewardsData).subscribe((responseData: AdrData) => {

      this.adrData = responseData;

      if (this.adrData.ShowTaxMessage) {

        let taxMsg = this.rewardInfoSrv.getTaxMessage(this.adrData.TaxMessageNumber,this.localModel);
        this.showNotificationMsg(this.localModel.lbl_ShowtaxMessage, taxMsg, false);         
      }
      else {

        if (!this.adrData.IsError) {
          window.location.href = this.adrData.AdrUrl;
        }
        else {
          this.showPopup(this.localModel.lbl_Popup_Heading_Alert, this.localModel.lbl_Popup_Body_Alert);
        }
      }

      this.spinnerSrv.hide();
    },
    errorResponse => {
      this.spinnerSrv.hide();
    });
  }

  // // return respective tax message according to tax message number
  // private getTaxMessage(taxMsgNumber: number): string {

  //   let taxMsg: string;

  //   switch (taxMsgNumber) {
  //     case 1:
  //       taxMsg = this.localModel.lbl_Tax_Message1;
  //       break;
  //     case 2:
  //       taxMsg = this.localModel.lbl_Tax_Message2;
  //       break;
  //     case 3:
  //       taxMsg = this.localModel.lbl_Tax_Message3;
  //       break;
  //     case 4:
  //       taxMsg = this.localModel.lbl_Tax_Message4;
  //       break;
  //     case 5:
  //       taxMsg = this.localModel.lbl_Tax_Message5;
  //       break;
  //     case 6:
  //       taxMsg = this.localModel.lbl_Tax_Message6;
  //       break;
  //     case 7:
  //       taxMsg = this.localModel.lbl_Tax_Message7;
  //       break;
  //     default:
  //       taxMsg = this.localModel.lbl_Tax_Message2;
  //       break;
  //   }

  //   return taxMsg;
  // }

  // show notification banner component
  private showNotificationMsg(header: string, message: string, showExtraBtn: boolean) {

    this.notificationHeader = header;
    this.notificationMsg = message;
    this.showExtraNotificationBtn = showExtraBtn;

    this.showNotification = true;
    window.scrollTo(0, 0);
  }

  // show popup for messages
  private showPopup(heading: string, message: string) {

    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }

  private showPopupForPointRestriction(heading: string, message: string) {

    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      },
      width:"auto"
    });
  }


  // event on close notification banner component
  onCloseNotification() {

    this.notificationHeader = '';
    this.notificationMsg = '';
    this.showExtraNotificationBtn = true;

    this.showNotification = false;
  }

}
