export abstract class AppConfig {
  /// Comma separated Sample names for which forced notification will be applicable 
  ForcedNotificationSamples: string;

  /// Maximum number of days to check if meter activity is present.
  MaxDaysForcedNotificationToShow: number;

  /// Set Password screen time out config
  SetPasswordPageExpiryInSeconds: number;
}
