<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no">

    </head>


<!-- Download meter notification     -->
<app-download-meter-notification *ngIf="showMeterDownloadNotification" > 
</app-download-meter-notification>

<!-- notification section -->
<app-important-notification *ngIf="!showMeterDownloadNotification && showNotification" [header]="notificationHeader" [content]="notificationMsg"
    [showExtraButton]="showExtraNotificationBtn"
    (closeNotification)="onCloseNotification()">  
</app-important-notification>

<!-- teen consent notification -->
<app-teen-consent *ngIf="!showMeterDownloadNotification && showTeenConsentMsg" [teenInfoList]="teenInfoList" [guardianMNO]="mno"
    (teenConsentMsgClose)="onCloseTeenConsentMsg()">
</app-teen-consent>

<!-- Main Content Here -->
<section class="dashboard-banner-head">
    <div class="container">
        <h1>{{ localModel.lbl_dashboard_MainTitle }}</h1>
        <p class="prompt"><strong class="fullname">{{ localModel.lbl_dashboard_titleDesc }} {{ firstName }}!</strong> {{ localModel.lbl_dashboard_titleDesc2 }}</p>
    </div>
  
</section>

<!-- phase 2 -->
<section class="dashboard">
    <div class="container">
        <app-rewards
        [balance]="pointsBalance" [cardButtonTitle]="localModel.lbl_Redemption_Temporarily_Blocked" [disableCardButton]="isBlockedForRedemption" (btnClicked)="redeemRewards()"></app-rewards>
        <div>
        <app-screenwisemeter id="screenwise-meter" fragment="screenwise-meter"></app-screenwisemeter>
        </div>
        <app-my-info-dashboard></app-my-info-dashboard>
        <app-household-info></app-household-info>
    </div>
</section>

<app-have-questions></app-have-questions>

<app-add-new-device *ngIf="isAddNewDevice"></app-add-new-device>

<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>
