		
import { Injectable } from "@angular/core";
import { AppConfig } from "./AppConfig";
import { HttpClient } from "@angular/common/http";
@Injectable({
    providedIn: 'root'
})
export class AppConfigService extends AppConfig {
    
    constructor(private httpClient: HttpClient) {
        super();
    }
    loadJsonConfig() {
        return this.httpClient.get<AppConfig>('AppConfig.json')
        .toPromise()
        .then(result => {
            // Set each config key values here...
            // this.ConfiguredIP = result.ConfiguredIP;
            this.ForcedNotificationSamples = result.ForcedNotificationSamples;
            this.MaxDaysForcedNotificationToShow = result.MaxDaysForcedNotificationToShow;
        }).catch(err => {
            console.log(err);
        })
    }
}