export class UserLogInfo{
        UserName :string;
        UserPassKey:string;
        MNo :number;
        MemberStatus:number;
        UserLoginResult:number;
        counter:boolean;    
        lockedPeriod:string;
        FirstName:string;
        token:string;
        panelmemberid:string;
        Lang:number;
        Localization:string;
        Age:number;  
        days:number;  
        panelname:string;
        LastName:string;
        IsPrimary:boolean;
        GrpNo:number;
        AccountId:string;
        PanelMemberId:string;
        SampleName: string;
        ConsentDate: Date;
        isMeterActivityValid: boolean;
}