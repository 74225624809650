import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { MemberAddressService } from 'src/app/services/memberAddress.service';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyProfileComponent implements OnInit {
  localModel: any;
  isPartialProfile: boolean = null;
  constructor(private myProfileService: MyProfileService,
    private memberAddressService: MemberAddressService,
    private localizationService: LocalizationService, private route: ActivatedRoute,
    private spinnerSrv: NgxSpinnerService, private titleService: Title) {
    this.localizationService.removeLocalStorageVariable("restrict")
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();

    this.titleService.setTitle(this.localModel.lbl_profile_browsertitle)

    this.localizationService.titleChanged.subscribe(res => {
      this.titleService.setTitle(this.localModel.lbl_profile_browsertitle)
    })

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.myProfileService.isPartiallyCreatedProfileSub.subscribe(res => {
      this.isPartialProfile = res;
    });
    this.spinnerSrv.show();
    this.getProfileInfo();
  }

  getProfileInfo() {
    this.myProfileService.getMemberProfile().subscribe(res => {
      if (res != null && res?.Data?.memberInfo !== null) {
        this.myProfileService.setProfile(res.Data.MemberInfo);
        if (this.myProfileService.isPartiallyCreatedProfileSub.getValue() !== false) {
          this.myProfileService.refreshPartiallyCreatedFlag(res.Data.MemberInfo.MNO);
        }

        this.memberAddressService.getMemberAddress(res.Data.MemberInfo.MNO);
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinnerSrv.hide();
        }, 1000);
      }
    });
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem('scrollToScreewiseMeterSettings')) {
      this.scroll();
    }
    this.route.fragment.subscribe(fragment => {
      if (fragment && fragment === 'google-screenwise-meter-account') {
        this.scroll();
      }
    });
  }

  scroll() {
    this.spinnerSrv.show();
    setTimeout(() => {
      this.spinnerSrv.hide();
      this.scrollToMyprofileScreenwiseDownloadMeters();
    }, 2000);
  }
  scrollToMyprofileScreenwiseDownloadMeters() {
    const element = document.getElementById('google-screenwise-meter-account');
    if (element) {
      const offsetTop = element.offsetTop;
      window.scroll(0, offsetTop - 20);
      sessionStorage.removeItem('scrollToScreewiseMeterSettings');
      localStorage.removeItem('reset-password');
    }
  }
}
