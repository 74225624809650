import { AfterViewInit,Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { SignInService } from 'src/app/services/signin.service';
import { DeviceService } from 'src/app/services/device.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RewardsInfoService } from 'src/app/services/my-rewards/rewardsInfo.service';
import { AdrData } from 'src/app/model/adrData.model';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLogInfo } from 'src/app/model/UserInfo';
import { LocalizationService } from 'src/app/services/localization.service';
import { TeenConsentService } from 'src/app/services/teen-consent/teenConsent.service';
import { MemberConsentInfo } from 'src/app/model/memberConsentInfo.model';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { TeenConsentInfo } from 'src/app/model/teenConsentInfo.model';
import { ConsentOption } from 'src/app/shared/shared.global';
import { environment } from 'src/environments/environment';
import { RewardsData } from 'src/app/model/rewardsData.model';
import { Title } from '@angular/platform-browser';
import { AppConfig } from 'src/app/Config/AppConfig';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { PointsDataModel } from 'src/app/model/PointsData.model';



@Component({
  selector: 'app-dashboard-banner',
  templateUrl: './dashboard-banner.component.html',
  styleUrls: ['./dashboard-banner.component.css']
})
export class DashboardBannerComponent implements OnInit,AfterViewInit,OnDestroy {
  isUserSignIn: boolean;
  mno: number;
  pointsBalance: number;
  firstName: string;
  panelName: string;
  notificationHeader: string;
  notificationMsg: string; 
  isAddNewDevice: boolean;
  showNotification: boolean;
  showTeenConsentMsg: boolean = false;
  checkForTaxCompliance: boolean;
  showExtraNotificationBtn: boolean;
  adrData: AdrData;
  teenInfoList: TeenConsentInfo[]; 
  showAddNewDeviceModelObs: Subscription;
  timeoutHandle: Subscription;
  timerStartHandle:Subscription;
  loginDetails: UserLogInfo;
  localModel: any;
  myProfile:MyProfileModel;
  rewardsData: RewardsData;
  showMeterDownloadNotification: boolean = false;
  /// This will be taken from AppConfig.json file. Default 30 if not found in config.
  maxDaysForcedNotificationToShow = 30;
  gotTeenConsent: boolean;
  gotPointBalance: boolean;
  IsScrolltodownloadMeter:boolean;
  returnUrl: any;
  currentURL:any;
  isBlockedForRedemption: boolean;

  constructor(private deviceService: DeviceService, private rewardInfoSrv: RewardsInfoService, private spinnerSrv: NgxSpinnerService, 
    private dialog: MatDialog, private router: Router, private localizationService: LocalizationService, private teenConsentSrv: TeenConsentService,private titleService:Title,private renderer: Renderer2,private el: ElementRef,
    private route: ActivatedRoute, private appConfig: AppConfig, private signInService: SignInService, private elementRef: ElementRef, private profileSvc: MyProfileService
  ) {

    
    // initialize variables
    if (this.appConfig.MaxDaysForcedNotificationToShow) {
      this.maxDaysForcedNotificationToShow = this.appConfig.MaxDaysForcedNotificationToShow;
    }
    this.isAddNewDevice = false;
    this.showNotification = false;
    this.showTeenConsentMsg = false;
    this.panelName = environment.PanelName;
    this.checkForTaxCompliance = environment.CheckForTaxCompliance;  

    if (localStorage.currentuser !== null) {

      this.loginDetails = this.localizationService.getCurrentUserVariable();
      this.mno = this.loginDetails.MNo;

      if (!sessionStorage.getItem('first-time-login') &&
        (this.loginDetails.isMeterActivityValid === null || this.loginDetails.isMeterActivityValid === undefined) &&
        this.CheckForcedNotificationVisibility()) {

        this.profileSvc.checkValidMeterActivity(this.mno).subscribe(result => {
          this.loginDetails.isMeterActivityValid = (result?.Data?.IsMeterActive ?? false);
        },
          () => {
            console.error('Failed to check meter activity');
            this.loginDetails.isMeterActivityValid = false;
          },
          () => {
            this.localizationService.setCurrentUserVariable(this.loginDetails);
            this.showMeterDownloadNotification = !this.loginDetails.isMeterActivityValid;
          });
      }
      
    var value = sessionStorage.getItem('myProfile')
    this.myProfile = JSON.parse(value)

    if (this.myProfile !== null) {
      this.firstName = this.myProfile.FirstName;
    }
    else {
      this.firstName = this.loginDetails.FirstName;
    }

      if (this.loginDetails.UserLoginResult === -3)
      {
        this.localizationService.setRestrictVariable();
      }
    }  
  }
  ngAfterViewInit(): void {
    
    this.route.fragment.subscribe(fragment => {
      if (fragment && fragment === 'screenwise-meter') {
        this.IsScrolltodownloadMeter = true;
           this.scrollIfCallBackDone()
             
      }
    });

    if (this.loginDetails.isMeterActivityValid !== null && this.loginDetails.isMeterActivityValid !== undefined) {
      this.showMeterDownloadNotification = !this.loginDetails.isMeterActivityValid;
    }    
  }
  
  ngOnInit(): void {

     // Start the page loader
    this.spinnerSrv.show();
 
    this.localModel = this.localizationService.getLocalization();

    this.localizationService.titleChanged.subscribe(res => {
      this.titleService.setTitle(this.localModel.lbl_dashboard_title)
    })
 
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.titleService.setTitle(this.localModel.lbl_dashboard_title)
    });
   
   // Temporary disabling Teen Reconsent Invitation/Reminder workflow as per 
   // User Story 89059: Screenwise: Disable the teen reconsent notification popup from member portal and stop the triggering of teen reconsent emails
  
    //this.showTeenConsent();
    this.getPointsBalance();
      
 
    this.showAddNewDeviceModelObs = this.deviceService.showAddNewDeviceModel.subscribe(status => {
      if (status == 1) {
        this.isAddNewDevice = true;
      } else {
        this.isAddNewDevice = false;
      }
    });
  }

  ngOnDestroy(): void {

    this.showAddNewDeviceModelObs.unsubscribe();
    if (this.timerStartHandle) this.timerStartHandle.unsubscribe();
    if (this.timeoutHandle) this.timeoutHandle.unsubscribe();
  }

  // Check if the forced notification needs to be shown
  CheckForcedNotificationVisibility(): boolean {
    const consentDate = new Date(this.loginDetails.ConsentDate).getTime();
    const timeNow = new Date().getTime();
    let daysDifference = Math.abs((timeNow - consentDate) / (1000 * 60 * 60 * 24))
    let isSampleValid = this.loginDetails.SampleName?.length > 0 &&
        this.appConfig.ForcedNotificationSamples.includes(this.loginDetails.SampleName)
    return isSampleValid && daysDifference < this.maxDaysForcedNotificationToShow 
  }

  // show teen consent message
  private showTeenConsent() {

    let mbrConsentInfo = new MemberConsentInfo();
    mbrConsentInfo.MNO = this.mno.toString();

    this.teenConsentSrv.getTeenConsentInfo(mbrConsentInfo).subscribe((responseData: TeenConsentInfo[]) => {

      this.teenInfoList = responseData;

      if (this.teenInfoList.length > 0) {

        this.teenInfoList.forEach(value => {
          value.CONSENT = ConsentOption.NotSelected;  // default
        });

        this.showTeenConsentMsg = true;
        window.scrollTo(0, 0);      
      }
    },
    errorResponse => {
      this.spinnerSrv.hide();
    },
    () => {
      this.gotTeenConsent = true
      this.scrollIfCallBackDone();
    });
  }
  
  // get current points balance from service
  private getPointsBalance() { 
    this.rewardInfoSrv.getPointsBalance(this.mno).subscribe((responseData: PointsDataModel) => {
      this.pointsBalance = responseData.PointsBalance;
      this.isBlockedForRedemption = responseData.IsBlockedForRedemption;
      if(!(sessionStorage.getItem('priviousroute')==='/changepassword')){ 
          if(this.isBlockedForRedemption){
            this.showPopupForPointRestriction(this.localModel.lbl_Popup_Heading_Alert, this.localModel.lbl_Redemption_Temporarily_Blocked);         
          }       
      }
      this.spinnerSrv.hide();
    },
    errorResponse => {
      this.spinnerSrv.hide();
      
    },
    () => {
      this.gotPointBalance = true
      this.scrollIfCallBackDone();
    });
  }

  // redirect user to ADR site
  redeemRewards() {    
    this.spinnerSrv.show();
    this.rewardsData= new RewardsData();
    this.rewardsData.MNO=this.mno;
    this.rewardsData.PanelName=this.panelName;
    this.rewardsData.CheckForTaxCompliance=this.checkForTaxCompliance;
    this.rewardsData.PanelMemberId=this.loginDetails.panelmemberid;
    this.rewardInfoSrv.getAdrData(this.rewardsData).subscribe((responseData: AdrData) => {

      this.adrData = responseData;

      if (this.adrData.ShowTaxMessage) {

        let taxMsg = this.rewardInfoSrv.getTaxMessage(this.adrData.TaxMessageNumber,this.localModel);
        this.showNotificationMsg(this.localModel.lbl_ShowtaxMessage, taxMsg, false);         
      }
      else {

        if (!this.adrData.IsError) {
          window.location.href = this.adrData.AdrUrl;
        }
        else {
          this.showPopup(this.localModel.lbl_Popup_Heading_Alert, this.localModel.lbl_Popup_Body_Alert);
        }
      }

      this.spinnerSrv.hide();
    },
    errorResponse => {
      this.spinnerSrv.hide();
    });
  }

  // show notification banner component
  private showNotificationMsg(header: string, message: string, showExtraBtn: boolean) {

    this.notificationHeader = header;
    this.notificationMsg = message;
    this.showExtraNotificationBtn = showExtraBtn;

    this.showNotification = true;
    window.scrollTo(0, 0);
  }

  // show popup for messages
  private showPopup(heading: string, message: string) {

    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }

  private showPopupForPointRestriction(heading: string, message: string) {
    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      },
      width: "auto"
    });
  }

  // event on close notification banner component
  onCloseNotification() {

    this.notificationHeader = '';
    this.notificationMsg = '';
    this.showExtraNotificationBtn = true;

    this.showNotification = false;
  }

 
  // event on close teen consent message
  onCloseTeenConsentMsg() {
    this.showTeenConsentMsg = false; 
  }

  scrollToScreenwiseDownloadMeters() {
    const element = document.getElementById('screenwise-meter');
    if (element) {
        const offsetTop = element.offsetTop;
        window.scroll(0, offsetTop - 20);
        sessionStorage.removeItem('HomeWelcome');
        localStorage.removeItem('reset-password');
      }
  }

  scrollIfCallBackDone() {
    if(this.gotPointBalance && this.gotTeenConsent && (sessionStorage.getItem('HomeWelcome') === '1'|| this.IsScrolltodownloadMeter)){
      this.scrollToScreenwiseDownloadMeters();
      this.IsScrolltodownloadMeter = false; 
    }
  }
}
