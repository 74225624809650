import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignInService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';
import { ContactData } from 'src/app/model/contact.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { ContactOption } from 'src/app/shared/shared.global';
import { SmtpEmailService } from 'src/app/services/smtp-email/smtpEmail.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Title } from '@angular/platform-browser';
import { ViewHelpCenterPopupComponent } from './viewhelpcenterpopup/viewhelpcenterpopup.component';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  mno: number;
  screenwidth: number;
  sitekey: string;
  userName: string;
  userEmail: string;
  userPhone: string;
  isUserSignIn: boolean;
  showCaptchaValidation: boolean;
  contactInfo: ContactData;
  contactForm: FormGroup;
  localModel: any;

  @ViewChild('captcha', { static: false }) captcha: ReCaptcha2Component;

  public size: string;
  public theme: 'light' | 'dark' = 'light';
  public lang = 'en';
  public type: 'image' | 'audio';
  
  constructor(private signInService: SignInService, private emailSrv: SmtpEmailService, private route: Router, 
    private localizationService : LocalizationService, private spinnerSrv: NgxSpinnerService, private dialog: MatDialog,private titleService:Title) {

    // initialize variables
    // this.sitekey = AppConfig.configurations.appSettings.SiteKey;
    this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
    this.showCaptchaValidation = false;
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }

  ngOnInit(): void {

    // setup the reactive contact-us form
    this.contactForm = new FormGroup({   
      'name': new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9 ]*')]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'phone': new FormControl(null, Validators.pattern('[0-9]{10}')),
      'howToContact': new FormControl(ContactOption.Email),
      'message': new FormControl(null, Validators.required)
    });    

    // get localization data
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_contactus_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_contactus_browsertitle)
      })
    
      this.localizationService.localizationChanged.subscribe(response => {
      this.localModel = response;
      this.lang=this.localizationService.getlocalizationfromSession();
      this.lang=this.lang=='english'?'en':'es';
    });

    // check if user is signedIn
    this.isUserSignIn = this.signInService.isSignIn();

    this.signInService.userLoginChanged.subscribe(res => {
      this.isUserSignIn = this.signInService.isSignIn();
    });

    if (this.isUserSignIn) {

      if (localStorage.currentuser !== null) {
        this.mno = JSON.parse(localStorage.currentuser).MNo;
      }  

      if (sessionStorage.getItem("myProfile") !== null) {

        this.userName = JSON.parse(sessionStorage.getItem("myProfile")).FullName;
        this.userEmail = JSON.parse(sessionStorage.getItem("myProfile")).EmailAddress1;
        this.userPhone = JSON.parse(sessionStorage.getItem("myProfile")).Phone;

        this.contactForm.patchValue({
          'name': this.userName,
          'email': this.userEmail,
          'phone': this.userPhone
        });
      }
    }
    else {
      // if not signed in then add extra racptcha control to form
      this.contactForm.addControl('recaptcha', new FormControl(null));
    }

    // subscribe event on contact preference radio button value change
    this.contactForm.get('howToContact').valueChanges.subscribe((value) => {

      if (value == ContactOption.Email) {

        this.contactForm.get('email').setValidators([Validators.required, Validators.email]);
        this.contactForm.get('email').updateValueAndValidity();

        this.contactForm.get('phone').setValidators(Validators.pattern('[0-9]{10}'));
        this.contactForm.get('phone').updateValueAndValidity();
      }
      else {

        this.contactForm.get('phone').setValidators([Validators.required, Validators.pattern('[0-9]{10}')]);
        this.contactForm.get('phone').updateValueAndValidity();

        this.contactForm.get('email').setValidators(Validators.email);
        this.contactForm.get('email').updateValueAndValidity();
      }
    });
    if (localStorage.currentuser !== null && localStorage.currentuser !== undefined) {
      this.showFaqPopup();
    }  
  }

  // on contact form submit
  onSubmit() {

    this.spinnerSrv.show();

    this.contactInfo = new ContactData();

    this.contactInfo.Name = this.contactForm.value.name;
    this.contactInfo.Email = this.contactForm.value.email;
    this.contactInfo.PhoneNumber = this.contactForm.value.phone;
    this.contactInfo.HowToContact = this.contactForm.value.howToContact;
    this.contactInfo.Message = this.contactForm.value.message;
   
  // send email to support team using SmtpEmail API
 
    if (!this.isUserSignIn) {
      if(this.contactForm.value.recaptcha===null || this.contactForm.value.recaptcha=== undefined){
        this.showCaptchaValidation = true;
          window.scrollTo(0, 0);
          this.spinnerSrv.hide();
          return;
      }
      
      this.emailSrv.sendEmail(this.contactInfo,this.contactForm.value.recaptcha).subscribe(() => {
   
        this.OnSuccess();
      },
      errorResponse => {
        this.OnError()
      });
    }
    else {  
      this.emailSrv.sendEmailAfterLogin(this.contactInfo).subscribe(()=>{
       this.OnSuccess();  
    },
    errorResponse => {
      this.OnError()
    }) 
}
}


  // show popup for messages
  private showPopup(heading: string, message: string) {

    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }

  // on top notification close button
  onClose() {

    this.showCaptchaValidation = false;

    // reset the form
    if (this.isUserSignIn) {
      this.contactForm.reset({
        'name': this.userName,
        'email': this.userEmail,
        'phone': this.userPhone,
        'howToContact': ContactOption.Email
      });
    }
    else {
      this.contactForm.reset({'howToContact': ContactOption.Email});
      this.captcha.resetCaptcha();
    }
  }

  // listen to event on window resize
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }


  OnSuccess(){
    this.spinnerSrv.hide();

      // reset the form
      if (this.isUserSignIn) {
        this.contactForm.reset({
          'name': this.userName,
          'email': this.userEmail,
          'phone': this.userPhone,
          'howToContact': ContactOption.Email
        });
      }
      else {
        this.contactForm.reset({'howToContact': ContactOption.Email});
        this.captcha.resetCaptcha();
      }
      
      this.showPopup(this.localModel.lbl_Popup_Heading_Success, this.localModel.lbl_Popup_Body_Success);
  }

  OnError(){
    this.spinnerSrv.hide();

    // reset the form
    if (this.isUserSignIn) {
      this.contactForm.reset({
        'name': this.userName,
        'email': this.userEmail,
        'phone': this.userPhone,
        'howToContact': ContactOption.Email
      });
    }
    else {
      this.contactForm.reset({'howToContact': ContactOption.Email});
      this.captcha.resetCaptcha();
    }
    
    this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Popup_Body_Failure);
  }
  
  handleReload(){
    this.lang = this.localizationService.getlocalizationfromSession()
    if(this.lang==null){
      this.lang='en';
      return;
    }
    this.lang=this.lang=='english'?'en':'es';
  }

  showFaqPopup() {
  if(localStorage.getItem('showviewhelpcenter') == 'true'){
    this.dialog.open(ViewHelpCenterPopupComponent, {

      disableClose: true
    });
  }
  }
}