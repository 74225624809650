import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransactionDetailsRequest } from "src/app/model/Request/TransactionDetailsRequest";
import { Globals } from "src/app/shared/shared.global";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class RewardsInfoService {
    apiBasePath: string;
    apiKey: string;
    httpOptions: { headers: HttpHeaders; };

    constructor(private http: HttpClient) {

        // this.apiBasePath = AppConfig.configurations.appSettings.Point_API_Base_Path;
        this.apiBasePath = environment.Point_API_Base_Path;
        // this.apiKey = AppConfig.configurations.appSettings.PointsAPIKey;
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };
    }

    getPointsBalance(mno: number) {

        return this.http.post(this.apiBasePath + Globals.GetPointsBalance, mno, this.httpOptions);
    }

    getAdrData(data:any) {

        return this.http.post(this.apiBasePath + Globals.GetAdrData, data,this.httpOptions);
    }

    getTransactionHistory(data:any) {

      return this.http.post(this.apiBasePath + Globals.GetTransactionHistory, data, this.httpOptions);
  }

  getAwardPointsDetails(data: TransactionDetailsRequest) {

    return this.http.post(this.apiBasePath + Globals.GetAwardPointsDetails, data, this.httpOptions);
}

getRedeemPointsDetails(data: TransactionDetailsRequest) {

  return this.http.post(this.apiBasePath + Globals.GetRedeemPointsDetails, data, this.httpOptions);
}

     // return respective tax message according to tax message number
   getTaxMessage(taxMsgNumber: number,localModel:any): string {
    
    return localModel["lbl_Tax_Message"+taxMsgNumber];
    
  }
}