import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-rewards-redeem',
  templateUrl: './rewards-redeem.component.html'
})
export class RewardsRedeemComponent implements OnInit {

  localModel: any;
  @Input() disableCardButton: boolean
  @Input() cardButtonTitle : string
  @Output() btnClicked = new EventEmitter();
    
  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void { 

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }
  
  onButtonClick() {
    if (!this.disableCardButton) {
      this.btnClicked.emit();
    }    
  }
}
