import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from "src/app/shared/shared.global";
import { UserLogInfo } from '../model/UserInfo';
import { APIResponse } from '../model/apiResponse.model';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { RecaptchaInfo } from '../model/recaptchaInfo.model';
import { environment } from 'src/environments/environment';
import { LocalizationService } from './localization.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({providedIn:'root'})
export class SignInService{
    
    user = new BehaviorSubject<User>(null);
    apiBasePath: string;
    apiKey: string;
    httpOptions: { headers: HttpHeaders; };
    userDetails:UserLogInfo
    timeoutExpiration:any;
    userLoginChanged= new Subject<UserLogInfo>();
    userData:UserLogInfo = new UserLogInfo();
    profiletogleChanged=new Subject<boolean>();
    notificationPOPup=new Subject<boolean>();
    redirectUrl:string;
    notification = new Subject<boolean>();
    isSignIn(){
     var userDataFromSession= this.localizationService.getCurrentUserVariable();
     var UserLoginResult = this.localizationService.getSessionVariables('loginresult')

     var sessionHeader=this.localizationService.getHeaderVariable();
     this.userDetails = userDataFromSession;
     if(sessionHeader==='true'){
       return false;
     }
     if(this.userDetails!==null && UserLoginResult!=='true' ){
      return true;
     }else{
       return false;
     }   
    }

    profileChanged(){
      return false;
    }
    notificationChanged(){
      return false;
    }
    constructor(private http:HttpClient,private router:Router ,private localizationService:LocalizationService,private spinner: NgxSpinnerService) {
        this.apiBasePath = environment.Login_API_Base_Path;
   
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',        
            'PanelName':environment.PanelName 
          })
        };
   }

    showSharedEmail(){
        const userData = new User(true);
        this.user.next(userData);
        return true;
    }
    
    

      getloginInfo(user: any) {
        this.httpOptions.headers =
        this.httpOptions.headers.set('Recaptcha', user.GRecaptchaResponse);
        return this.http.post<APIResponse>(this.apiBasePath + Globals.GetAuthenticateUserDetails,
           user,this.httpOptions);
      }
      updateLogoutInfo(user: any): Promise<any> {
        return this.http.post(this.apiBasePath + Globals.UpdateLogoutAuditlog, user, this.httpOptions).toPromise();
      }   

      getNeoCode(user: any): Promise<any> {
        this.httpOptions.headers =
        this.httpOptions.headers.set('Recaptcha', user.GRecaptchaResponse);
        return this.http.post(this.apiBasePath + Globals.GetOffPanelDetails, user,this.httpOptions).toPromise();  //https://localhost:44352/ webapi host url  
      }

      authenticateGRecaptcha(recaptchaInfo: RecaptchaInfo): Promise<any> {
        return this.http.post(this.apiBasePath + Globals.AuthenticateGRecaptcha, recaptchaInfo, this.httpOptions).toPromise();
      }

      insertAuditDetailsLog(data): Promise<any> {
        return this.http.post(this.apiBasePath + Globals.AuditDelatilsLogs, data,this.httpOptions).toPromise();    
      }

      getlinkexpirydata(data :any,captcha:string):Promise<any> {
        this.httpOptions.headers =
       this.httpOptions.headers.set('Recaptcha', captcha);
        return this.http.post(this.apiBasePath + Globals.CheckLinkExpiry,JSON.stringify(data),this.httpOptions).toPromise();
      }
      updatedresetpassword(data :any):Promise<any> {
        this.httpOptions.headers =
       this.httpOptions.headers.set('Recaptcha', data.Captcha);
        return this.http.post(this.apiBasePath+ Globals.ResetPasswordInformation, data,this.httpOptions).toPromise();
      }

      logout() {        
        this.userData=this.localizationService.getCurrentUserVariable();
        this.updateLogoutInfo(this.userData).then((res:any)=>{})
        this.localizationService.removeLocalStorageVariable('currentuser')
        this.localizationService.removeLocalStorageVariable('showviewhelpcenter')
        if(this.localizationService.getRemembermeVariable() === null){
          this.localizationService.removeLocalStorageVariable('username')
          this.localizationService.removeLocalStorageVariable('isRememberme')        
        }
        sessionStorage.clear();
        if(this.timeoutExpiration){
          clearTimeout(this.timeoutExpiration);
        }
        this.timeoutExpiration = null;
        this.userLoginChanged.next();
        this.router.navigate(["/signout"]);
      }

      gettoken(){ 
        return !!this.localizationService.getCurrentUserVariable();  
      } 
      
      navigateHomepage(){
        this.spinner.hide();
      }
}
