import {  Component, OnInit, ViewChild, ElementRef, HostListener} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordService } from 'src/app/services/forgotPassword.service';
import { SignInService } from 'src/app/services/signin.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { UserLogInfo } from '../../model/UserInfo';
import { UserLoginDetails } from 'src/app/model/Request/LoginDetails';
import{UserStatus} from '../../model/UserLoginResult';
import { String } from 'typescript-string-operations';
import {  UserIdleService } from 'angular-user-idle';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from 'src/app/services/localization.service';
import { APIResponse } from 'src/app/model/apiResponse.model';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { ChatbotComponent } from 'src/app/Common/chatbot/chatbot.component';
import { Renderer2 } from '@angular/core';
import { AppConfig } from 'src/app/Config/AppConfig';
import { Globals } from 'src/app/shared/shared.global';
import { MyProfileService } from 'src/app/services/myProfile.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
   providers: [ChatbotComponent]
})
export class SignInComponent implements OnInit {
  @ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
 
  sitekey: string; 
  mcafeeAlignment :string= "right";

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  // public size: 'compact' | 'normal' = 'normal';
  public type: 'image' | 'audio';

  public size: string;
  screenwidth:number;
  showForgotPassword:boolean = false;
  showSharedEmail:boolean = false;
  isShowCheckEmailID:boolean = false;
  isShowSharedEmail:boolean = false;
  userData: UserLogInfo;
  userLoginRequest: UserLoginDetails;
  lockoutMsg: string;
  showValidationMessages:boolean=false;
  showLinkExpired: boolean = false;
  isUserInvalid:boolean=false;
  isUserRetired:boolean=false;
  isUserWithdrawn:boolean=false;
  response: APIResponse;
  isAccountLocked:boolean=false;
  isInvalidCaptcha:boolean=false;
  withdrawnDays:number;
  submitted: boolean=false;
  sessionstorage: boolean = false;
  form:NgForm;
  show : boolean=false;
  email: any;
  username: any;
  password:any;
  isRemberchecked: string;
  isRememberChecked: boolean = false;
  localModel:any;
  returnUrl: any;
  isLinkInvalid: boolean;
  recaptchaVisible: boolean;
  lang :string;
  constructor(private route:ActivatedRoute,
              private router:Router,
              private signinService:SignInService,
              private forgotPasswordService:ForgotPasswordService,
              private usrIdle: UserIdleService,private spinner: NgxSpinnerService,private localizationService:LocalizationService,private titleService:Title,
              private chatBot: ChatbotComponent,private renderer: Renderer2, private el: ElementRef, public appConfig: AppConfig,
              private profileService: MyProfileService) {
                if(localStorage.getItem("SiteValue")!==null)
                this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));                            
                this.localizationService.removeLocalStorageVariable('currentuser');
                this.localizationService.removeLocalStorageVariable('restrict');
                this.localizationService.removeLocalStorageVariable('header');    
                this.localizationService.removesessionStorageVariable('loginresult'); 
                this.localizationService.removesessionStorageVariable('myProfile');   
                this.localModel = this.localizationService.getLocalization();  
                
                this.localizationService.localizationChanged.subscribe(res => {
                  this.localModel = res;
                  this.lang=this.localizationService.getlocalizationfromSession();
                  this.lang=this.lang=='english'|| this.lang==null?'en':'es';     
                });                       
               }
 
  ngOnInit(): void {
    
    if (sessionStorage.getItem('LinkExpiredMessage')) {
      this.showLinkExpired = true;
      this.isLinkInvalid = true;
      sessionStorage.removeItem('LinkExpiredMessage');
    }
    
    this.titleService.setTitle(this.localModel?.lbl_home_title)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel?.lbl_home_title)
      })
 
    this.recaptchaVisible= this.localizationService.getIsSignOutVariable();
    if(this.recaptchaVisible==true){
     this.localizationService.setIsSignOutVariable("false");  
      this.lang=this.lang=='english'?'en':'es';   
    }
   
    this.userData = new UserLogInfo();
    this.isRemberchecked = this.localizationService.getRemembermeVariable();
    var localdata = this.localizationService.getUserNameVariable();
    if (this.isRemberchecked=="true") {
      this.username = localdata;
      
      this.isRememberChecked = true;
    }
    this.forgotPasswordService.forgotPasswordChanged.subscribe(
      forgotPassword =>
      {
        if(forgotPassword.isSharedEmailId){
          this.isShowSharedEmail = true;
          this.showForgotPassword = false;                
        }
        else{
          this.isShowCheckEmailID = true;
          this.isShowSharedEmail = false;     
          this.showForgotPassword = false;  
        }
      }
    ); 
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";

    this.forgotPasswordService.checkEmailIdChanged.subscribe(res => {    
      this.onCloseCheckEmailId();
    });

    this.route.queryParams.subscribe(params => {
      const returnUrl = params['returnUrl']
      if (returnUrl === '/home/account'){
        this.router.navigate(['myprofile'], {fragment: 'google-screenwise-meter-account'} );
      }
      if (returnUrl === '/home/welcome') {
      this.router.navigate(['dashboard'], { fragment: 'screenwise-meter' });
      }
     
     
      });

  }

  onCloseSharedEmail(){
    this.isShowSharedEmail = false;
  }

  
  onCloseCheckEmailId(){
    this.showForgotPassword=false;
    this.isShowSharedEmail = false;  
    this.isShowCheckEmailID = false;
  }

  onSubmit(signin:NgForm):void{
    this.isAccountLocked=null;
    this.isUserWithdrawn=null;
    this.isUserRetired=null;
    this.isInvalidCaptcha=null;
    this.isUserInvalid=null;
    this.submitted = true;
    if(signin.invalid){
      
      return;
    }
  
    if(signin.value.recaptcha===null||signin.value.recaptcha===undefined ||signin.value.recaptcha===""){
    this.showValidationMessages=true;
    this.isInvalidCaptcha=true;
    this.scrollOnTheView();
    return;
    }

    this.userLoginRequest= new UserLoginDetails();
    this.userLoginRequest.UserName=signin.value.email;
    this.userLoginRequest.Password=signin.value.password;
    this.userLoginRequest.GRecaptchaResponse=signin.value.recaptcha;
    this.userLoginRequest.PanelName=environment.PanelName;    

    this.email = signin.value.email;
    this.getloginInfo(); 
  }

  onEnrollment(){
    this.router.navigate(['enroll'])    
  }

  onForgotPassword(){
    this.chatBot.CloseChatWindow();
    this.showForgotPassword = true;  
  }

  onClose(form:NgForm){
    this.showForgotPassword=false;
    this.showValidationMessages = false;
    this.captchaElem.resetCaptcha();
    this.localizationService.changeLocalization(this.localizationService.getlocalizationfromSession())
    form.setValue({ 'email':  this.email, 'password': '', 'recaptcha': '' });
  }

  onLinkExpiredClose() {
    this.showLinkExpired = false;
    this.isLinkInvalid = false;
  }

  handleSuccess(data) {
  }
  handleReload(){
   this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
   

    this.lang = this.localizationService.getlocalizationfromSession();
    if(this.lang==null){
      this.lang='en';
      return;
    }
    this.lang=this.lang=='english'?'en':'es'; 
  }

  getloginInfo() {
    this.spinner.show();
    this.usrIdle.stopWatching();
    this.signinService.getloginInfo(this.userLoginRequest).subscribe(
      res =>{
        this.usrIdle.setConfigValues({idle:environment.SessionTimeout,timeout:1,ping:0})
        this.usrIdle.startWatching();
        this.fillObject(res.Data);
        this.signinService.userLoginChanged.next();  
      }
    );
      
  }
  onbtnClick(){
    this.show =!this.show;    
  }


  fillObject(res){
    if (res !== null) {
      this.userData = new UserLogInfo();
      this.userData = res.UserDetails;     
      this.lockoutMsg = String.Format(this.localModel.lbl_your_accountislocked, this.userData.lockedPeriod);
      this.userData.Localization = res.UserDetails.Localization == 1 ? "en-US": res.UserDetails.Localization==0 ? this.lang : "es-MX";
      this.userData.panelname=environment.PanelName;
      this.localizationService.setViewHelpCenterSession('true');
      switch (this.userData.UserLoginResult) {
        case UserStatus.NeedPassReset:
          switch (this.userData.MemberStatus) {
            case UserStatus.withdrawn:
              this.localizationService.setCurrentUserVariable(this.userData);
              this.localizationService.setSesionVariables('loginresult')
              if (this.router.url === Globals.WelcomeHomeBookmark) {
                sessionStorage.setItem('welcome', '1');
              }
              else if (this.router.url === Globals.AccountHomeBookmark) {
                sessionStorage.setItem('WelcomeAccount', '1');
              } 
              this.router.navigate(['changepassword'])               
              break;
            case UserStatus.retired:
              this.showValidationMessages=true;
              this.isUserRetired=true;
              this.scrollOnTheView();
              break;
            case UserStatus.invalid:
              this.showValidationMessages=true;
              this.isUserInvalid=true;
              this.scrollOnTheView();
              break;
            case UserStatus.inactive:             
              break;
            default:
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';    
              if (this.returnUrl?.length > 0) {
                sessionStorage.setItem('redirectUrl', this.returnUrl);
              }
              this.localizationService.setCurrentUserVariable(this.userData);
              this.localizationService.setSesionVariables('loginresult')
              sessionStorage.setItem('first-time-login', '1');
              localStorage.setItem('reset-password', '1');
              if (this.router.url === Globals.WelcomeHomeBookmark) {
                sessionStorage.setItem('welcome', '1');
              }
              else if (this.router.url === Globals.AccountHomeBookmark) {
                sessionStorage.setItem('WelcomeAccount', '1');
              } 
              this.router.navigate(['changepassword'])
              break;
          }
          break;
        case UserStatus.PasswordLockout:      
             this.showValidationMessages=true;
             this.isAccountLocked=true;
             this.scrollOnTheView();
          break;
        case UserStatus.InvalidPass:
          this.showValidationMessages=true;
          this.isUserInvalid=true;
          this.scrollOnTheView();
          break;
        case UserStatus.exception:
          this.showValidationMessages=true;
          this.isUserInvalid=true;
          this.scrollOnTheView();
          break;
        case UserStatus.valid:
          this.sessionstorage=true;
        //  this.router.navigate(['dashboard'],{relativeTo:this.route})    
          break;
      }
      switch (this.userData.MemberStatus) {
        case UserStatus.withdrawn:
        this.withdrawnDays=environment.counter;
        if(this.userData.days>this.withdrawnDays){
          this.showValidationMessages=true;
          this.isUserWithdrawn=true;
          this.sessionstorage=false;
          this.scrollOnTheView();
        }else {
          this.sessionstorage=false;    
          this.localizationService.setCurrentUserVariable(this.userData);
          this.router.navigate(['myrewards'],{relativeTo:this.route})  
          }     
          break;
        case UserStatus.retired:
          this.showValidationMessages=true;
          this.isUserRetired=true;
          this.sessionstorage=false;
          this.scrollOnTheView();
          break;
        case UserStatus.invalid:
          this.showValidationMessages=true;
          this.isUserInvalid=true;
          this.sessionstorage=false;
          this.scrollOnTheView();
          break;
        case UserStatus.inactive:
          break;
        default:
          break;
      }
     
      var userLang=this.userData.Localization==="en-US" || this.userData.Localization==="en" ?"english":"spanish"
      this.localizationService.changeLocalization(userLang)    
      if(this.sessionstorage){       
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';    
        this.localizationService.setCurrentUserVariable(this.userData);
        this.localizationService.removeLocalStorageVariable('username');
        this.localizationService.removeLocalStorageVariable('isRememberme');
        if (this.isRememberChecked) {
          this.localizationService.setRemembermeVariable(this.isRememberChecked); 
          this.localizationService.setUserNameVariable(this.userData.UserName);            
        }

        if (this.router.url === Globals.WelcomeHomeBookmark) {
          sessionStorage.setItem('HomeWelcome', '1');
          this.router.navigate(['/dashboard']);
        }
        else if (this.router.url === Globals.AccountHomeBookmark) {
          sessionStorage.setItem('scrollToScreewiseMeterSettings', '1');
          this.router.navigate(['/myprofile']);
        } 
        else if (this.returnUrl != "/")
        {
          this.router.navigateByUrl(this.returnUrl);
        } 
        else {          
          this.router.navigate(['/dashboard']);
        }
        this.profileService.isPartiallyCreatedProfileSub?.next(this.userData.isPartiallyCreated);  
      }
      this.spinner.hide();

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }

  scrollOnTheView(){
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    }
 
}


