import { Component, ElementRef,AfterViewChecked, ViewChild, HostListener} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgxSpinnerService } from 'ngx-spinner';
import { NeoCodeRequest } from 'src/app/model/Request/NeoCodeData';
import { IResponse } from 'src/app/model/Response/IResponse';
import { PostNeoCodeService } from 'src/app/services/postneocode.service';
import { SignInService } from 'src/app/services/signin.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html'
})
export class EnrollmentComponent implements AfterViewChecked {
  @ViewChild('formauthenticate') formauthenticate:ElementRef;
  @ViewChild('captchaElem', { static: true }) recaptcha: ReCaptcha2Component;
  @ViewChild('enrollment') enrollment: NgForm;
  response: IResponse;
  request: NeoCodeRequest;
  errorLink:string;
  posting:boolean=false;
  neoCode;string;
  ddLang:number;
  surveyLink:string;
  showValidationMessages:boolean=false;
  isInvalidNeoCode:boolean=false;
  isInvalidCaptcha:boolean=false;
  formAuthenticateClicked:boolean=false;
  form:NgForm;
  sitekey:string;
  top:any;
  codeAutoSet:boolean=false;
  public theme: 'light' | 'dark' = 'light';
  public size: string;
  public lang = 'en';
    public type: 'image' | 'audio';
    localModel: any;
    screenwidth:number;
    constructor(private router: Router, private route: ActivatedRoute, private signinService: SignInService, private neoService: PostNeoCodeService,
        private spinner: NgxSpinnerService, private localizationService: LocalizationService,private titleService:Title) {
          this.localizationService.localizationChanged.subscribe(res => {
            this.spinner.show();
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
          }, 3000);
            this.localModel = res;
            this.lang=this.localizationService.getlocalizationfromSession();
            this.lang=this.lang=='english'?'en':'es';
          });

        this.errorLink=environment.ErrorLink;
        this.surveyLink=environment.SurveyLink;
        this.ddLang = 1;
        this.top = document.getElementById('top')

  }


  ngOnInit(): void {

    if (this.router.url.split('?')[0] === '/enroll/es') {
      this.localizationService.changeLocalization('spanish');
    }
    else {
      this.route.queryParams.subscribe(params => {
        const queryLang = params['lang'];
        if (queryLang && (queryLang === 'en' || queryLang === 'es')) {
          this.localizationService.changeLocalization(queryLang === 'es' ? 'spanish' : 'english');
        }
      });
    }
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_enroll_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_enroll_browsertitle)
      })
      
    this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));        
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }  
  handleSuccess(data) {
  }
  handleReload(){
    this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));  
    this.lang = this.localizationService.getlocalizationfromSession();
    if(this.lang==null){
      this.lang='en';
      return;
    }
    this.lang=this.lang=='english'?'en':'es';
  }
  onSubmit(enrollment:NgForm){
    this.isInvalidNeoCode=null;
    this.isInvalidCaptcha=null;
    if(enrollment.invalid){
      return;
    }
    if(enrollment.value.recaptcha===null||enrollment.value.recaptcha===undefined ||enrollment.value.recaptcha===""){
      this.showValidationMessages=true;
     this.isInvalidCaptcha=true;
     this.ngAfterViewInit(true, true);
     this.spinner.hide();
      return;
      }

    this.request= new NeoCodeRequest();
    this.request.NeoCode=(enrollment.value.code1+
                                       enrollment.value.code2+
                                       enrollment.value.code3+
                                       enrollment.value.code4+
                                       enrollment.value.code5+
                                       enrollment.value.code6);
    this.request.GRecaptchaResponse=enrollment.value.recaptcha;
    this.getNeoCode();
  }
  async getNeoCode() {
    this.spinner.show();
    this.response = await this.signinService.getNeoCode(this.request);
    if (this.response != null && this.response.Data["isValid"] == true){
      this.neoCode=this.response.Data["neoCode"];
      this.posting=true;
      this.formAuthenticateClicked=true;
    }else{      
      this.neoCode=this.response.Data["neoCode"];
      this.posting=true;
      this.formAuthenticateClicked=true;
      this.ngAfterViewInit(true, true);
      this.showValidationMessages=true;
      this.isInvalidNeoCode=true;
    }
    this.spinner.hide();
  }
  ngAfterViewChecked(){
    if (this.posting && this.formauthenticate) {
      this.formauthenticate.nativeElement.click();
      this.formAuthenticateClicked=false;
    }
      this.autopopulateNeoCode();  
  }
  onContactUs(){
    this.router.navigate(['../contact-us'],{relativeTo:this.route})
  }
  
  onClose(enrollment:NgForm){
    this.showValidationMessages = false;
    enrollment.resetForm();
    this.recaptcha.resetCaptcha();
    if(this.codeAutoSet)
    {
      this.router.navigateByUrl("/enroll");
    }
  }

  ngAfterViewInit(resetpass: boolean = true, isCustomCall: boolean = false) {
    // Hack: Scrolls to top of Page after page view initialized
   // let top = document.getElementById('top');
   window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  }); 
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }

  autopopulateNeoCode() {
    if (this.codeAutoSet) {
      return;
    }
    let neocode = this.route.snapshot.paramMap.get('neocode');
    if (neocode != null) {
      if (neocode.length == 6) {
        this.enrollment.controls.code1.setValue(neocode[0]);
        this.enrollment.controls.code2.setValue(neocode[1]);
        this.enrollment.controls.code3.setValue(neocode[2]);
        this.enrollment.controls.code4.setValue(neocode[3]);
        this.enrollment.controls.code5.setValue(neocode[4]);
        this.enrollment.controls.code6.setValue(neocode[5]);
        this.codeAutoSet = true;
      }
      else {
        this.router.navigateByUrl("/enroll");
      }
    }
  }

}
