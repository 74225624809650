<div class="rewards-info">
    <br>
    <h3>{{ localModel.lbl_MyReward_RedeemingRewards }}</h3>

    <p>{{ localModel.lbl_MyReward_RedeemRewards_Lineone }}</p>

    <p>{{ localModel.lbl_MyReward_RedeemRewards_LineTwo }}</p>

    <p [title]="disableCardButton? cardButtonTitle:''">
        {{ localModel.lbl_MyReward_RedeemRewards_LineThree_Part1 }}
      
        <button class="rewards-button" [disabled]="disableCardButton" (click)="onButtonClick()">
            {{ localModel.lbl_MyReward_RedeemRewards_LineThree_Link }}
            <img src="assets/images/icons/icon_link_external.svg" class="img--inline" alt="icon"> 
        </button>

        <span [innerHtml]="localModel.lbl_MyReward_RedeemRewards_LineThree_Part2"></span>
    </p>

    <ul class="list-bulleted">
        <li [innerHtml]="localModel.lbl_MyReward_RedeemRewards_LineFour"></li>
        <li [innerHtml]="localModel.lbl_MyReward_RedeemRewards_LineFive"></li> 
        <li [innerHtml]="localModel.lbl_MyReward_RedeemRewards_LineSix"></li>
    </ul>
    
</div>