import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalizationService } from '../services/localization.service';
import { interval, of } from 'rxjs';
import { switchMap, takeWhile, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-donot-sell-info',
  templateUrl: './donot-sell-info.component.html',
  styleUrls: ['./donot-sell-info.component.css']
})
export class DonotSellInfoComponent implements OnInit {

  localModel : any;

  constructor(private titleService: Title, private localizationService: LocalizationService,private spinnerSrv: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.localModel = this.localizationService.getLocalization();
    
    if(!this.localModel) {
      this.retrieveDataUntilAvailable();
    }
    this.titleService.setTitle(this.localModel.lbl_DNSMI_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_DNSMI_browsertitle)
      })
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

  retrieveDataUntilAvailable() {
    const pollingInterval$ = interval(2000); 
    pollingInterval$.pipe(
      switchMap(() => of(this.localizationService.getLocalization())),
      takeWhile(data => !data, true), 
      catchError(err => {
        return of(null); 
      })
    ).subscribe(data => {
      if (data) {
        this.localModel =data;
        this.spinnerSrv.hide();
      } else {
        this.spinnerSrv.show();
      }
    });
  } 
  
}
