import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocalizationService } from 'src/app/services/localization.service';
import { environment } from 'src/environments/environment';
import { ChatbotService } from 'src/app/services/chatbot/chatbot.service';


@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],

})

export class ChatbotComponent {
@ViewChild('iframe') iframe: ElementRef;
 chatBotLink: string;
 disableChatIcon: boolean = true;
 showPopup: boolean = false;
 lang: any;
 jsLink: any;
 height: number = 480;
 width: number = 460;
 urlSafe: SafeResourceUrl;
 mno: number;
 localModel: any;
  constructor(private localizationService : LocalizationService, public sanitizer: DomSanitizer, private chatService: ChatbotService) {   
  }

  ngOnInit(): void { 

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

  @HostListener('window:beforeunload')
  CloseChatWindow(): void {
    if (this.chatService.chatWindowOpened) {
      this.chatService.chatWindowOpened = false;
      this.chatService.chatWindow?.close()
    }
  }

   getIframeContent() {
    this.CloseChatWindow();
    this.lang= this.localizationService.getlocalizationfromSession();
    this.jsLink = this.lang == 'spanish' ? environment.ChatBotURL_Spanish : environment.ChatBotURL_English;
    var params = `height=${this.height},width=${this.width},top=${window.innerHeight},left=${window.innerWidth}`;

    if (localStorage.currentuser !== null) {
      this.mno = JSON.parse(localStorage.currentuser).MNo;
    }  
    this.jsLink = this.jsLink + "&uid="  + this.mno;
    
    this.chatService.chatWindow = window.open(this.jsLink, "chatBotWindowSOW", params); 
    this.chatService.chatWindowOpened = true;
    const chatCheckInterval = setInterval(() => {
      if (this.chatService.chatWindow.closed) {
        this.chatService.chatWindowOpened = false;
        clearInterval(chatCheckInterval);
      }
    }, 1000)
  } 
}