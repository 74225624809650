import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResetPassword } from 'src/app/model/Request/ResetPassword';
import { IResponse } from 'src/app/model/Response/IResponse';
import { LocalizationService } from 'src/app/services/localization.service';
import { SignInService } from 'src/app/services/signin.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { Title } from '@angular/platform-browser';
import { ReCaptcha2Component } from 'ngx-captcha';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  toggleLen :boolean = false;
  toggleUp :boolean = false;
  toggleLow :boolean = false;
  toggleNum :boolean = false;
  toggleSpch :boolean = false;
  value :string;
  type :string;
  toggle : boolean=false;
  show : boolean=false;
  retype : boolean=false;
  resetData: ResetPassword;
  response:IResponse;
  showValidationMessages:boolean= false;
  isUserResetLinkExpired:boolean=false;
  isSuccess:boolean=false;
  localModel: any;
  passwordPolicy : string;
  isPasswordPolicy:boolean = false;
  isFailed:boolean=false;
  isCombinationExists: boolean;
  isInvalid: boolean;
  showCaptchaValidation:boolean=false;
  sitekey:string;
  screenwidth:number;
  public theme: 'light' | 'dark' = 'light';
  public size: string;
  public lang = 'en';
  public gtype: 'image' | 'audio';
  showCaptchaValidationMessages:boolean=false;
  @ViewChild('captchaElem', { static: true }) recaptcha: ReCaptcha2Component
  constructor(private route: ActivatedRoute,private localizationService: LocalizationService,private router:Router,private spinner:NgxSpinnerService,private signservice: SignInService,private dialog:MatDialog,private titleService:Title) { 
    if(localStorage.getItem("SiteValue")!==null)
      this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
  
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.lang=this.localizationService.getlocalizationfromSession();
      this.lang=this.lang=='english'?'en':'es';
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.localModel.lbl_resetpwd_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_resetpwd_browsertitle)
    });
  }
  handleReload(){
    this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
               
    this.lang = this.localizationService.getlocalizationfromSession();
    if(this.lang==null){
      this.lang='en';
      return;
    }
    this.lang=this.lang=='english'?'en':'es';
  }
  onkeyUp(event){
    this.value = event.target.value;
    var array : string[]=[];
    document.querySelector("[data-check=length]").className = this.value.length >= 7 ? "met" : "not-met";
    array.push(document.querySelector("[data-check=length]").className);

    this.passwordPolicy =  document.querySelector("[data-check=uppercase]").className = this.value.match(/[A-Z]/) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=uppercase]").className);

    this.passwordPolicy =  document.querySelector("[data-check=lowercase]").className = this.value.match(/[a-z]/) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=lowercase]").className);
    this.passwordPolicy =  document.querySelector("[data-check=number]").className = this.value.match(/\d/) ? "met" : "not-met";
    array.push(document.querySelector("[data-check=number]").className);
    this.passwordPolicy =  document.querySelector("[data-check=specialcharacter]").className = this.value.match(
        /[!@#$%^&*~`()_+\-=\[\]{};':"\\|,.<>\/?]+/
      )
        ? "met"
        : "not-met";     

        array.push(document.querySelector("[data-check=specialcharacter]").className);   
     if(array.filter(policy => policy == "not-met").length > 0){
       this.isPasswordPolicy = false;
     }   else{
       this.isPasswordPolicy = true;
     }
      //console.log(array);
   }

   onbtnClick(){
    this.show =!this.show;    
  }
  onbtnRetype(){
    this.retype =!this.retype;   
  }
  onSubmit(reset:NgForm){
    this.isFailed=null;
    this.isSuccess=null;
    this.isUserResetLinkExpired=null;
    this.isCombinationExists=null;
    this.isInvalid=null;
    this.showCaptchaValidation=false;
    if(reset.invalid){
      return;
    }
    if(reset.value.recaptcha==="" || reset.value.recaptcha=== undefined){
      this.showCaptchaValidationMessages=true;
      this.showCaptchaValidation = true;
      this.scrollOnTheView();    
        return;
    }
  this.resetData= new ResetPassword();
    this.resetData.validationKey = reset.value.resetkey;
    this.resetData.NewPassword = reset.value.newPassword
    this.resetData.ResetUrl = this.route.snapshot.queryParamMap.get('id');
    this.resetData.Captcha=reset.value.recaptcha;
    this.getdata(this.resetData);
  }
  async getdata(data: any) {
    this.spinner.show();   
      var resp = await this.signservice.updatedresetpassword(data).catch((expResp) => {
        this.showValidationMessages=true
        this.isFailed=true;
        this.scrollOnTheView();
        this.spinner.hide();
      });
      if(resp==null){
        return;
      }
      if (resp.Data["isResetLinkExpired"] == true) {
        this.showValidationMessages=true
        this.isUserResetLinkExpired=true;
        this.scrollOnTheView();
      }
      if (resp != null && resp.Data["isSuccess"] === true) {
       //redirected to logoin and show the suceess msg
       this.router.navigate(['/'])
        this.isSuccess=true;
        this.showPopup(this.localModel.lbl_ChangePass_Title,this.localModel.lbl_reset_valid_8);
      }
      if  (resp !== undefined && resp.Data !== null && resp.Data["IsCombinationExists"] === true) {
        this.showValidationMessages = true;
        this.isCombinationExists=true;
        this.scrollOnTheView();     
      }

      if (resp !== null && resp !== undefined && resp.Data !== null && resp.Data["IsInvalid"] === true) {
        this.showValidationMessages = true;
        this.isInvalid=true;
        this.scrollOnTheView();     
      }
      
      if (resp !== null && resp !== undefined && resp.Data !== null && resp.Data["isFailed"] === true) {
        this.showValidationMessages = true;
        this.isFailed=true;
        this.scrollOnTheView();     
      }
    
    this.spinner.hide();

  }
  onClose(form:NgForm){
        this.showValidationMessages = false;
        this.recaptcha.resetCaptcha();
        form.resetForm();
  }
  scrollOnTheView(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}

private showPopup(heading: string, message: string) {

  this.dialog.open(PopupComponent, {

    disableClose: true,
    data: {
      header: heading,
      content: message
    }
  });
}
onCloseSection(){
  this.showCaptchaValidationMessages = false;
  this.recaptcha.resetCaptcha();
}
@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }
}
